import React from 'react';
import Clock from './Components/Clock/Clock';

function App() {
  return (
    <>
      <Clock/>
    </>
  );
}

export default App;
